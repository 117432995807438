import React , {useState} from 'react'
const Main = ({dark}) => {

  return (
    <>
        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className={`how_it_inner ${dark && "dark"}`}>
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>Roadmap</span></h2>
                        <p>Based on the development plan for HEALr, here’s a projected roadmap with key milestones:</p>
                    </div>
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Alpha Release </h4>
                                <p>(Launch to Alpha End: Present - 2024-08-30)</p>
                                </div>
                                <div className="step_number">
                                <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <p>Secure Authentication on the platform</p>
                                    <p>Use gamification technique and AI to determine the user profile based on needs/issues</p>
                                    <p>Identify the healer/list of healers that went through the same situation and surpassed it</p>
                                    <p>Protecting User Privacy through anonymous healing conversations between a chosen healer and the user</p>
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                <h4>Beta Release</h4>
                                <p>(Alpha End to Beta End 2024-08-30 -2-24-12-08)</p>
                                </div>
                                <div className="step_number"> 
                                <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                    <p>Schedule a healing plan based on the availability of both user and healer</p>
                                    <p>After each session, both user and healer will offer Feedback of the experience</p>
                                    <p>Leaderboard that tracks the continuous improvement/evolution path of the healers</p>
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Gamma Release</h4>
                                <p>(Beta End to Gamma End 2024-12-09 -2025-05-08)</p>
                                </div>
                                <div className="step_number">
                                <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <p>Generating a Healing Journey plan with the help of AI to match user needs</p>
                                    <p>Extensively embacing the power of AI in combating abuse, determining the mood, surveilling the conversation, providing recommendations, and tracking the evolution of the healing process</p>
                                    <p>Building a global community of healers that will benefit both B2C and B2B users on mental healing.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="section-footer">
                        <p>This roadmap provides a structural timeline for rolling out HEALr features, focusing on building a secure, empathic, and effective platform for users and healers alike.</p>
                    </div>
                </div>
            </div>
        </section>
       
    </>
  )
}

export default Main