import React from 'react'
import img1 from '../../../assets/images/modern01.png'
import img2 from '../../../assets/images/secure_data.png'
import img3 from '../../../assets/images/modern02.png'
import img4 from '../../../assets/images/modern03.png'

const Main = ({gredient}) => {
  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src="assets/images/section-bg.png" alt="image"/> </div>}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Why <span>HEALr?</span></h2>
                        </div>
                        <ul className="design_block">
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Personalized Discovery</h4>
                            <p>Through gamification and cutting-edge AI, we craft a profile that mirrors your unique experience and needs.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Find your Healer</h4>
                            <p>AI assists in connecting you with healers who've successfully navigated similar challenges, ensuring empathy and understanding at every step.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Healing on Your time</h4>
                            <p>Schedule your healing sessions at times that work best for you and your chosen healer, making healing a harmonious part of your life.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Privacy at Heart</h4>
                            <p>Engage in anonymous conversations, allowing for open, honest communication with your healer, free from the worry of exposure.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Mutual Growth</h4>
                            <p>Post-session feedback from both you and healer refines and enhances the healing experience, fostering mutual growth.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>A Tailored Healing Journey</h4>
                            <p>AI designs a personalized Healing Journey Plan, aligning with your needs to accelerate your path to  wellness.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Recognition and Reward</h4>
                            <p>Join the HEALr Leaderboard, where healers are celebrated for their impact, encouraging a culture of excellence and reward.</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                            <h4>Join a Global Movement</h4>
                            <p>Be part of HEALr’s worldwide community, a network of healers breaking barriers in both personal and professional spheres, promoting mental health healing everywhere.</p>
                            </li>
                        </ul>
                        </div>
                    </div>
                    {/* TODO: app images */}
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                        <div className="left_img">
                            <img className="moving_position_animatin" src={img1} alt="image" />
                        </div>
                        <div className="right_img">
                            <img className="moving_position_animatin" src={img2} alt="image" />
                            <img className="moving_position_animatin" src={img3} alt="image" />
                            <img className="moving_position_animatin" src={img4} alt="image" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main